var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getPlans } from '@/services/api/facilityAPI';
import { HStack, Stack, useToast } from '@chakra-ui/react';
import { LoadingWithControl } from '@frontend/design-system/components/LoadingWithControl/LoadingWithControl';
import { useFacility } from '@frontend/domain/contexts/Facility/FacilityHook';
import { useOrganizationSummaries } from '@frontend/domain/contexts/Organization/OrganizationSummaryContext';
import { PlannerSettingsProvider } from '@frontend/domain/contexts/Settings/PlannerSettingsProvider';
import { useOrganization } from '@frontend/domain/hooks/Organization/OrganizationHook';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { appValues } from '../../../config/constants';
import { FacilityPageProvider } from '../../../contexts/Facilities/FacilityPageProvider';
import FloorPlanWrapper from '../../Main/Canvas/FloorPlanWrapper';
import { DetailsSidebar } from '../../Main/Sidebar/DetailsSidebar/DetailsSidebar';
import { PlansSidebar } from '../../Main/Sidebar/PlansSidebar/PlansSidebar';
import { FacilityPageHeader } from './FacilityPageHeader';
export const FacilityPage = () => {
    // TODO:  an api with 'compressed' in the path is being called and failing; it doesn't seem to be affecting anything. The use of this will likely be removed during the photo migration work. Otherwise, it has been noted in card 4194 as an api to look at for cleanup.
    const { selectedOrganizationIdentity } = useOrganizationSummaries();
    const { organizationDetails } = useOrganization({
        organizationIdentity: selectedOrganizationIdentity,
    });
    const { fid } = useParams();
    const { facility, isLoading: isFacilityLoading } = useFacility({
        facilityIdentity: Number(fid),
    });
    const [currentPlans, setCurrentPlans] = useState([]);
    const [isPlanLoading, setIsPlanLoading] = useState(true);
    const toast = useToast();
    const isLoading = isFacilityLoading && isPlanLoading;
    useEffect(() => {
        if ((facility === null || facility === void 0 ? void 0 : facility.identity) &&
            (!currentPlans || currentPlans.length === 0)) {
            fetchPlans(facility.identity);
        }
    }, [facility === null || facility === void 0 ? void 0 : facility.identity]);
    const fetchPlans = (facilityIdentity) => __awaiter(void 0, void 0, void 0, function* () {
        setIsPlanLoading(true);
        try {
            const res = yield getPlans(facilityIdentity);
            setCurrentPlans(res);
        }
        catch (err) {
            console.error('couldnt fetch plans: ', err);
            toast({
                title: 'Failed to fetch plans',
                status: 'error',
                isClosable: true,
            });
        }
        finally {
            setIsPlanLoading(false);
        }
    });
    const updateCurrentPlans = (
    // TODO: replace selectedPlan with identity when createSpace endpont availiable
    selectedPlan, updatedPlan) => __awaiter(void 0, void 0, void 0, function* () {
        setCurrentPlans((prevPlans) => {
            const updatedPlans = prevPlans.map((plan) => {
                // TODO: change this to use filter
                if (plan.identity !== selectedPlan.identity) {
                    return plan;
                }
                return updatedPlan;
            });
            return updatedPlans;
        });
    });
    const handlePlanCreate = (planToCreate) => __awaiter(void 0, void 0, void 0, function* () {
        setCurrentPlans((prevPlans) => {
            const updatedPlans = [...prevPlans, planToCreate];
            return updatedPlans;
        });
    });
    const handlePlanDelete = (planToDelete) => __awaiter(void 0, void 0, void 0, function* () {
        setCurrentPlans((prevPlans) => {
            const updatedPlans = prevPlans.filter((plan) => plan.identity !== planToDelete.identity);
            return updatedPlans;
        });
    });
    return (_jsxs(FacilityPageProvider, Object.assign({ plans: currentPlans }, { children: [_jsxs(Stack, Object.assign({ bg: 'misc.bgGrey', color: 'slgq.primBlue', h: '100%', spacing: 0, w: '100%', zIndex: '1', overflow: 'hidden' }, { children: [_jsx(FacilityPageHeader, { orgId: organizationDetails === null || organizationDetails === void 0 ? void 0 : organizationDetails.identity, orgName: organizationDetails === null || organizationDetails === void 0 ? void 0 : organizationDetails.name, siteName: facility === null || facility === void 0 ? void 0 : facility.siteName, facilityName: facility === null || facility === void 0 ? void 0 : facility.name }), _jsxs(HStack, Object.assign({ h: `calc(100vh - ${appValues.HEADER_HEIGHT_MAIN}px)`, spacing: 0 }, { children: [_jsx(PlansSidebar, { plans: currentPlans, updateCurrentPlans: updateCurrentPlans, handlePlanCreate: handlePlanCreate, handlePlanDelete: handlePlanDelete, facilityIdentity: facility === null || facility === void 0 ? void 0 : facility.identity }), _jsx(HStack, Object.assign({ sx: { touchAction: 'none' }, h: `calc(100vh - ${appValues.HEADER_HEIGHT_MAIN}px)`, w: '100vw' }, { children: _jsx(Splitter, Object.assign({ layout: 'vertical', style: {
                                        height: '100%',
                                        width: '100%',
                                    } }, { children: _jsx(SplitterPanel, Object.assign({ style: { overflowX: 'hidden' }, size: 99.9 }, { children: currentPlans && currentPlans.length > 0 && (
                                        // TODO: potential refactor to call a hook instead of using the provider
                                        _jsx(PlannerSettingsProvider, Object.assign({ orgIdentity: selectedOrganizationIdentity }, { children: _jsx(FloorPlanWrapper, {}) }))) })) })) })), selectedOrganizationIdentity && (_jsx(DetailsSidebar, { orgIdentity: selectedOrganizationIdentity, facilityIdentity: facility === null || facility === void 0 ? void 0 : facility.identity }))] }))] })), _jsx(LoadingWithControl, { isLoading: isLoading })] })));
};
