var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { convertFacilityFormToIConfigureFacility } from '../../models/Facility/FacilityConversions';
import { useFacilityService } from '../../services/FacilityService';
import { useDependencies } from '../Dependencies/DependenciesContext';
export const useFacility = (facilityHookProps) => {
    const { facilityIdentity, shouldFetchOnInit = true, onFacilityUpdate, } = facilityHookProps;
    const { platformApi } = useDependencies();
    const { facility: { fetch, upsert }, } = useFacilityService({ platformApi });
    const [isLoading, setIsLoading] = useState(false);
    const [facility, setFacility] = useState();
    useEffect(() => {
        if (shouldFetchOnInit) {
            fetchFacility(facilityIdentity);
        }
    }, [facilityIdentity, shouldFetchOnInit]);
    const fetchFacility = (facilityIdentity) => {
        if (facilityIdentity) {
            setIsLoading(true);
            fetch(facilityIdentity)
                .then((res) => setFacility(res))
                .finally(() => setIsLoading(false));
        }
    };
    const updateFacility = (facilityData, facilityPhoto, facilityIdentity, orgIdentity, facilityTemplate) => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        const convertedFacility = convertFacilityFormToIConfigureFacility(facilityData, facilityIdentity, facilityTemplate, facilityPhoto);
        convertedFacility &&
            updateFacilityWithConfiguredFacility(convertedFacility, orgIdentity, !facilityIdentity);
    });
    const updateFacilityWithConfiguredFacility = (facility, orgIdentity, isCreate) => __awaiter(void 0, void 0, void 0, function* () {
        return upsert(facility, orgIdentity)
            .then((res) => {
            if (onFacilityUpdate) {
                onFacilityUpdate(res);
            }
            if (!isCreate) {
                setFacility(res);
            }
        })
            .finally(() => setIsLoading(false));
    });
    return {
        isLoading,
        facility,
        updateFacility,
        updatePriorityFacility: updateFacilityWithConfiguredFacility,
        fetchFacility,
    };
};
