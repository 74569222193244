var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BLOB_STORE_CONTAINER_URL } from '../services/fileUpload';
export const toCurrentYYYYMMDDHHMMSSMSMS = () => {
    const date = new Date();
    const dateString = `${date.getFullYear()}${date.getMonth() + 1}${date.getDate()}-${date.getHours()}${date.getMinutes()}${date.getMilliseconds()}-`;
    return dateString;
};
export const toBlobStoreContainerUrl = (fileName) => {
    const resultUrl = `${BLOB_STORE_CONTAINER_URL}/${toContainerPath(fileName, 'compressed')}`;
    return resultUrl;
};
export const toContainerPath = (fileName, type) => {
    const containerPath = `${type ? type + '/' : ''}${fileName}`;
    return containerPath;
};
export const toCleanFileName = (fileName) => {
    const splittedFileName = fileName.split('.');
    const extension = splittedFileName.pop();
    const cleanedName = splittedFileName
        .join('.')
        .replace(/[!*'();:@&=+$,/?%#[\].\\]+|\s+/g, '-');
    return cleanedName + '.' + extension;
};
export const getImageUrl = (url) => __awaiter(void 0, void 0, void 0, function* () {
    return url;
});
export const handlePhotoUploadErrorMessage = (file, supportedFormats, validFileSize = 3 * 1024 * 1024 // 3 MB
) => {
    if (file) {
        const fileExtension = file.name
            .substring(file.name.lastIndexOf('.'))
            .toLowerCase();
        if (!supportedFormats.includes(fileExtension)) {
            return `Allowed picture types: ${supportedFormats.join(', ')}`;
        }
        else if (file.size > validFileSize) {
            return `Max file size: ${validFileSize / 1024 / 1024} MB`;
        }
    }
    return undefined;
};
