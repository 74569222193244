import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useBreakpointValue } from '@chakra-ui/react';
import { useOrganizationSummaries } from '@frontend/domain/contexts/Organization/OrganizationSummaryContext';
import { useCurrentUser } from '@frontend/domain/contexts/User/UserContext';
import { useReportList } from '@frontend/domain/hooks/Report/ReportListHook';
import { useEffect } from 'react';
import { PageHeader } from '../../pages/Selection/Header/PageHeader';
import { ExpandableSidebar } from '@frontend/shared/src/components/ExpandableSidebar/ExpandableSidebar';
import { environment } from '@/config/environment';
import { IAppNames } from '@frontend/domain/models/App';
export const PageLayout = ({ title, children, onAddFacility, hideAddFacilityButton = true, canAdd = false, }) => {
    var _a;
    const { selectedOrganizationIdentity, organizations, isLoading, onSelectOrganization, } = useOrganizationSummaries();
    const { user } = useCurrentUser();
    const { fetch, reports } = useReportList();
    const showAddFacilityButton = !hideAddFacilityButton && canAdd;
    useEffect(() => {
        if (selectedOrganizationIdentity) {
            fetch(selectedOrganizationIdentity);
        }
    }, [selectedOrganizationIdentity]);
    const dropdownLabel = (_a = useBreakpointValue({
        base: 'Org',
        lg: 'Organization',
    })) !== null && _a !== void 0 ? _a : 'Organization';
    const externalBasePaths = {
        base: environment.msiqUrl,
        organize: environment.fcaUrl,
        prioritize: environment.plannerUrl,
        opportunities: environment.piqUrl,
    };
    return (_jsx(ExpandableSidebar, Object.assign({ orgIdentity: selectedOrganizationIdentity, reports: reports, user: user, externalBasePaths: externalBasePaths, app: IAppNames.FCA }, { children: _jsxs(_Fragment, { children: [_jsx(PageHeader, { title: title !== null && title !== void 0 ? title : '', selectedOrganizationIdentity: selectedOrganizationIdentity, organizations: organizations, isLoading: isLoading, onSelectOrganization: onSelectOrganization, onAddFacility: onAddFacility, dropdownLabel: dropdownLabel, showAddFacilityButton: showAddFacilityButton }), children] }) })));
};
