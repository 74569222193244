import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { useAppAccess, useFacilityAccess, } from '@frontend/domain/contexts/Authorization/UserAccess';
import { IAppNames } from '@frontend/domain/models/App';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AppLoading } from '../Loading/AppLoading';
import { AppPaths } from '../Routing/RouteConstants';
export function RequireFacilityAccess({ unauthorizedPage, children, }) {
    const { hasAccess: hasAppAccess } = useAppAccess(IAppNames.FCA);
    const { fid } = useParams();
    const facilityIdentity = Number(fid);
    const navigate = useNavigate();
    if (!fid && !isNaN(facilityIdentity)) {
        navigate(AppPaths.organization.base);
        return _jsx(_Fragment, {});
    }
    const { hasAccess, isLoading } = useFacilityAccess(facilityIdentity);
    return (_jsx(_Fragment, { children: isLoading ? (_jsx(AppLoading, {})) : !hasAccess || !hasAppAccess ? (unauthorizedPage) : (children) }));
}
