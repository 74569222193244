export const validateLocationFields = (location) => {
    for (const entry of Object.entries(location)) {
        const [key, value] = entry;
        if (!value && key !== 'address2') {
            return toFriendlyAddressValidation(key);
        }
    }
    return undefined;
};
const toFriendlyAddressValidation = (key) => {
    switch (key) {
        case 'address1':
            return 'The address does not match the address provided. Select an option that matches on the entered address.';
        case 'city':
            return 'The city does not match the address provided. Select an option that matches on the entered city.';
        case 'stateOrProvince':
            return 'The state does not match the address provided. Select an option that matches on the selected state.';
        case 'postalCode':
            return 'The zip code does not match the address provided. Select an option that matches on the selected zip code.';
        default:
            return `The ${key} does not match the address provided. Select an option that matches on the selected ${key}`;
    }
};
