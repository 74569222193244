import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Card, Flex, HStack, Image, useDisclosure, VStack, } from '@chakra-ui/react';
import { PopoverMenu, } from '@frontend/design-system/components/ActionMenu/PopoverMenu';
import { NumberData } from '@frontend/design-system/components/DataDisplay/NumberData';
import { WithIcon } from '@frontend/design-system/components/DataDisplay/WithIcon';
import { DeleteModal } from '@frontend/design-system/components/DeleteModal/DeleteModal';
import { NavLink } from '@frontend/design-system/components/NavBar/NavLink';
import Body from '@frontend/design-system/components/Typography/Body/Body';
import PreTitleStrong from '@frontend/design-system/components/Typography/PreTitleStrong/PreTitleStrong';
import { useAppAccess } from '@frontend/domain/contexts/Authorization/UserAccess';
import { useOrganizationSummaries } from '@frontend/domain/contexts/Organization/OrganizationSummaryContext';
import { IAppNames } from '@frontend/domain/models/App';
import { DefaultImageIcon } from '@frontend/shared/src/components/DefaultImageIcon/DefaultImageIcon';
import React from 'react';
import { MdCalendarToday, MdGroups, MdPinDrop, MdSquareFoot, } from 'react-icons/md';
import { useFacilityExport } from '../../contexts/Facilities/FacilityExportHook';
import { FacilityModal } from '../../pages/Selection/Facilities/FacilityModal';
export const FacilityCard = ({ linkTo, facility: facilitySummary, onFacilityUpdate, orgIdentity, canManageFacility, facilityTemplate, validateFacilityName, deleteFacility, canViewFacility, }) => {
    var _a, _b;
    const { exportFiles, isExporting } = useFacilityExport(orgIdentity, facilitySummary.identity, facilitySummary.name);
    const { hasAccess } = useAppAccess(IAppNames.FCA);
    const { selectedOrgPhoto } = useOrganizationSummaries();
    const hasPhoto = (_a = facilitySummary.photoUrl) !== null && _a !== void 0 ? _a : selectedOrgPhoto;
    const facilityEditDisclosure = useDisclosure();
    const facilityDeleteDisclosure = useDisclosure();
    const openEditModal = () => {
        facilityEditDisclosure.onOpen();
    };
    const openDeleteModal = () => {
        facilityDeleteDisclosure.onOpen();
    };
    const actionMenuItems = [
        {
            label: 'Export FCA',
            onClick: () => exportFiles(),
            isDisabled: isExporting,
        },
        {
            label: 'Edit',
            onClick: () => openEditModal(),
        },
        ...(facilitySummary.isRemovable
            ? [
                {
                    label: 'Delete',
                    onClick: () => openDeleteModal(),
                },
            ]
            : []),
    ];
    return (_jsxs(Card, Object.assign({ flexDir: 'row', p: 2, gap: 4, height: '100%', justifyContent: 'space-between', "data-testid": 'facility-card' }, { children: [_jsx(Flex, Object.assign({ width: '30%', height: '135px' }, { children: hasPhoto ? (_jsx(Image, { objectFit: 'contain', width: '100%', borderRadius: '4px', src: (_b = facilitySummary.photoUrl) !== null && _b !== void 0 ? _b : selectedOrgPhoto, alt: `Image of ${facilitySummary.name}` })) : (_jsx(DefaultImageIcon, { styles: { width: '30px', height: '30px' } })) })), _jsxs(Flex, Object.assign({ width: '67%', direction: 'column', textAlign: 'left', gap: 1 }, { children: [_jsxs(Box, Object.assign({ pointerEvents: !canViewFacility || !hasAccess ? 'none' : 'auto' }, { children: [_jsx(Body, { children: facilitySummary.siteName }), _jsx(NavLink, Object.assign({ href: linkTo, color: !canViewFacility || !hasAccess
                                    ? 'base.black'
                                    : 'brand.primBlue' }, { children: _jsx(PreTitleStrong, Object.assign({ wordBreak: 'break-word', "data-testid": 'facility_link', color: !canViewFacility || !hasAccess
                                        ? 'base.black'
                                        : 'brand.primBlue' }, { children: facilitySummary.name })) }))] })), _jsxs(HStack, Object.assign({ gap: 2 }, { children: [_jsxs(Flex, Object.assign({ direction: 'column', gap: 1, alignItems: 'start' }, { children: [_jsx(WithIcon, Object.assign({ mdIcon: _jsx(MdPinDrop, {}), position: 'left' }, { children: _jsxs(Body, { children: [facilitySummary.location.address1, ', ', facilitySummary.location.stateOrProvince] }) })), _jsx(WithIcon, Object.assign({ mdIcon: _jsx(MdSquareFoot, {}), position: 'left' }, { children: _jsx(NumberData, { value: facilitySummary.totalSize || 0, unit: facilitySummary.sizeUnits.toLowerCase() }) }))] })), _jsxs(Flex, Object.assign({ direction: 'column', gap: 1, alignItems: 'start', height: '100%' }, { children: [facilitySummary.latestEnrolledCount !== undefined && (_jsx(WithIcon, Object.assign({ mdIcon: _jsx(MdGroups, {}), position: 'left' }, { children: _jsx(NumberData, { value: facilitySummary.latestEnrolledCount, unit: 'students' }) }))), _jsx(WithIcon, Object.assign({ mdIcon: _jsx(MdCalendarToday, {}), position: 'left' }, { children: _jsx(Body, { children: facilitySummary.yearBuilt }) }))] }))] }))] })), _jsxs(VStack, Object.assign({ width: '3%' }, { children: [canManageFacility && (_jsx(PopoverMenu, { menuItems: actionMenuItems, position: 'left' })), canManageFacility && (_jsx(FacilityModal, { facilityIdentity: facilitySummary.identity, facilityDisclosure: facilityEditDisclosure, facilityTemplate: facilityTemplate, orgIdentity: orgIdentity, validateFacilityName: validateFacilityName, onUpdateFacility: onFacilityUpdate })), facilitySummary.isRemovable && (_jsx(DeleteModal, { disclosure: facilityDeleteDisclosure, onDelete: () => deleteFacility(facilitySummary.identity), text: 'Deleting a facility removes all facility projects, all floorplans, spaces, assets, and photos. This action cannot be undone.' }))] }))] })));
};
