import { jsx as _jsx } from "react/jsx-runtime";
import { useDisclosure } from '@chakra-ui/react';
import { useFcaPermissions } from '@frontend/domain/contexts/Authorization/UserPermission';
import { useFacilityList } from '@frontend/domain/contexts/Facility/FacilityListHook';
import { useOrganizationSummaries } from '@frontend/domain/contexts/Organization/OrganizationSummaryContext';
import { useUser } from '@frontend/domain/contexts/User/UserDataHook';
import { AppLoading } from '@frontend/shared/src/components/Loading/AppLoading';
import React from 'react';
import { PageLayout } from '../../../components/layouts/PageLayout';
import { MyFacilities } from './MyFacilities';
export const FacilitiesPage = () => {
    const { selectedOrganizationIdentity, isLoading: orgListLoading } = useOrganizationSummaries();
    const { facilityTemplate, isLoading: facilitiesInfoLoading, facilities, onUpdateFacility, importFacilities, deleteFacility, } = useFacilityList(selectedOrganizationIdentity);
    const { user } = useUser();
    const { canManageFacility } = useFcaPermissions();
    const facilityDisclosure = useDisclosure();
    const isLoading = orgListLoading || facilitiesInfoLoading;
    return (_jsx(PageLayout, Object.assign({ title: 'myFacilities', onAddFacility: () => {
            facilityDisclosure.onOpen();
        }, hideAddFacilityButton: false, canAdd: canManageFacility }, { children: isLoading || !selectedOrganizationIdentity ? (_jsx(AppLoading, {})) : (_jsx(MyFacilities, { orgIdentity: selectedOrganizationIdentity, facilities: facilities, facilityTemplate: facilityTemplate, canManageFacility: canManageFacility, facilityDisclosure: facilityDisclosure, onUpdateFacility: onUpdateFacility, importFacilities: importFacilities, deleteFacility: deleteFacility, user: user })) })));
};
