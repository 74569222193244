import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AddIcon } from '@chakra-ui/icons';
import { Flex, Heading, HStack } from '@chakra-ui/react';
import Button from '@frontend/design-system/components/Button/Button';
import { Dropdown } from '@frontend/design-system/components/Toolbar/Dropdown';
import { appColors } from '@frontend/design-system/theme/constants';
import { AppPaths } from '@frontend/shared/src/components/Routing/RouteConstants';
import { useLocation, useNavigate } from 'react-router-dom';
//TODO: remove duplication in all PageHeaders
/** Replaces old org base path with new org  */
const setNewOrgInPath = (path, newOrgIdentity, currentOrgIdentity) => {
    if (currentOrgIdentity) {
        return path.replace(AppPaths.organization.withOrgId(currentOrgIdentity), AppPaths.organization.withOrgId(newOrgIdentity));
    }
    else {
        return path;
    }
};
export const PageHeader = ({ title, selectedOrganizationIdentity, organizations, isLoading, onSelectOrganization, onAddFacility, dropdownLabel, showAddFacilityButton, }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const handleOrgChange = (orgIdentity) => {
        onSelectOrganization(orgIdentity);
        const url = setNewOrgInPath(location.pathname, orgIdentity, selectedOrganizationIdentity);
        navigate(url);
    };
    return (_jsx(_Fragment, { children: _jsx(Flex, Object.assign({ as: 'header', w: '100%', alignItems: 'center', px: 10, py: { base: 2, lg: 3 }, borderBottom: `1px solid ${appColors.BORDER_SEC_LIGHT_GRAY}` }, { children: _jsxs(HStack, Object.assign({ justifyContent: 'space-between', w: '100%', h: '100%' }, { children: [_jsx(Heading, Object.assign({ as: 'h1', fontSize: { base: '24px', xl: '32px' }, fontWeight: 400, color: appColors.TEXT_COLOR, whiteSpace: 'nowrap' }, { children: title })), _jsxs(HStack, Object.assign({ gap: 10 }, { children: [_jsx(Dropdown, { dropdownValues: organizations.map((org) => ({
                                    label: org.name,
                                    value: org.identity,
                                })) || [], label: dropdownLabel, isLoading: isLoading, handleChange: handleOrgChange, value: selectedOrganizationIdentity || '', bgColor: appColors.PRIM_BLUE }), showAddFacilityButton && onAddFacility && (_jsx(Button, { onClick: onAddFacility, buttonType: 'secondary', "data-testid": 'new-facility', icon: _jsx(AddIcon, { boxSize: '16px', w: '9px', h: '9px' }), iconPosition: 'left', text: 'New Facility' }))] }))] })) })) }));
};
