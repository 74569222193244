import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Link as ChakraLink } from '@chakra-ui/react';
import React from 'react';
import { Link as ReactLink } from 'react-router-dom';
export const NavLink = ({ children, href, external, color, }) => {
    if (external) {
        return (_jsx(ChakraLink, Object.assign({ href: href, color: color }, { children: children })));
    }
    else if (href) {
        return (_jsx(ChakraLink, Object.assign({ as: ReactLink, color: color, to: href }, { children: children })));
    }
    else {
        return _jsx(_Fragment, { children: children });
    }
};
