import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, VStack } from '@chakra-ui/react';
import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { VscAdd } from 'react-icons/vsc';
import { FacilityAdditionInput } from './FacilityAdditionInput';
const emptyAdditionForm = {
    yearBuilt: '',
    squareFootage: '',
};
export const FacilityAdditions = ({ facilityForm }) => {
    const { fields, append, remove } = useFieldArray({
        control: facilityForm.control,
        name: 'additions',
    });
    return (_jsx(Box, Object.assign({ px: 4 }, { children: _jsxs(VStack, Object.assign({ spacing: 4, align: 'flex-start' }, { children: [fields.map((field, index) => (_jsx(FacilityAdditionInput, { index: index, removeAddition: () => remove(index), formContext: facilityForm }, field.id))), _jsx(Button, Object.assign({ onClick: () => append(emptyAdditionForm), variant: 'link', leftIcon: _jsx(VscAdd, {}), fontWeight: 500, size: '14px', lineHeight: '22px', isDisabled: fields.length === 15, color: 'brand.primBlue' }, { children: "Add new" }))] })) })));
};
