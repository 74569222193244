export const parseCurrencyAsFloat = (value) => {
    const cleanValue = value.replace(/,/g, '');
    const intValue = parseFloat(cleanValue);
    return intValue;
};
export const parseNumberAsString = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
export const stringToInt = (value) => {
    return !value || value.length < 1 ? undefined : parseInt(value);
};
