var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, VStack, } from '@chakra-ui/react';
import React from 'react';
import Button from '../Button/Button';
import Body from '../Typography/Body/Body';
export const DeleteModal = ({ disclosure, onDelete, labels = {
    apply: 'Delete',
    cancel: 'Cancel',
}, text, size = 'md', }) => {
    return (_jsxs(Modal, Object.assign({ isOpen: disclosure.isOpen, onClose: disclosure.onClose, isCentered: true, size: size }, { children: [_jsx(ModalOverlay, {}), _jsxs(ModalContent, Object.assign({ padding: 10 }, { children: [labels.title && _jsx(ModalHeader, { children: labels.title }), _jsx(ModalCloseButton, { size: 'md' }), _jsx(ModalBody, Object.assign({ overflow: 'auto', padding: 0, mb: 10 }, { children: _jsxs(VStack, Object.assign({ alignItems: 'start', gap: 7 }, { children: [_jsx(Body, { children: text }), _jsx(Body, { children: "Are you sure you want to continue?" })] })) })), _jsxs(ModalFooter, Object.assign({ justifyContent: 'space-between', padding: 0 }, { children: [_jsx(Button, { text: labels.cancel, buttonType: 'secondary', onClick: disclosure.onClose }), _jsx(Button, { text: labels.apply, buttonType: 'primary', color: 'ui.danger', onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                    onDelete();
                                    disclosure.onClose();
                                }) })] }))] }))] })));
};
