var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Button, IconButton } from '@chakra-ui/react';
import { useState } from 'react';
import { IoMdDownload } from 'react-icons/io';
import { variants } from '../../theme/theme';
const downloadFileLink = (file) => {
    const href = URL.createObjectURL(file);
    const link = document.createElement('a');
    link.href = href;
    link.download = file.name;
    link.click();
    URL.revokeObjectURL(href);
};
export const useFileDownload = (fetchFile) => {
    const [isDownloading, setIsDownloading] = useState(false);
    const downloadFile = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsDownloading(true);
        const file = yield fetchFile();
        if (file) {
            downloadFileLink(file);
        }
        else {
            console.warn('No file found to download');
        }
        setIsDownloading(false);
    });
    return { downloadFile, isDownloading };
};
export const FileDownloadButton = ({ fetchFile, buttonName, isDisabled = false, showIcon = true, variant = variants.blueOutlineBtn, }) => {
    const { downloadFile, isDownloading } = useFileDownload(fetchFile);
    return buttonName ? (_jsx(Button, Object.assign({ "aria-label": 'file-download-button', variant: variant, leftIcon: showIcon ? _jsx(IoMdDownload, {}) : _jsx(_Fragment, {}), onClick: downloadFile, isDisabled: isDisabled || isDownloading }, { children: buttonName }))) : (_jsx(IconButton, Object.assign({ "aria-label": 'file-download-button', variant: variant, onClick: downloadFile, isDisabled: isDisabled || isDownloading, width: '56px', height: { base: '28px', lg: '36px' } }, { children: _jsx(IoMdDownload, {}) })));
};
