var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useFileDownload } from '@frontend/design-system/components/Buttons/FileDownloadButton';
import { useDependencies } from '@frontend/domain/contexts/Dependencies/DependenciesContext';
import { useFacilityService } from '@frontend/domain/services/FacilityService';
export const useFacilityExport = (orgIdentity, facilityIdentity, facilityName) => {
    const { platformApi } = useDependencies();
    const { facility } = useFacilityService({ platformApi });
    const exportCSVFiles = () => __awaiter(void 0, void 0, void 0, function* () {
        return facility
            .export(facilityIdentity, orgIdentity, `${facilityName} FCA Export.zip`, 'application/zip')
            .then((response) => {
            return response;
        });
    });
    const { downloadFile, isDownloading } = useFileDownload(exportCSVFiles);
    return { exportFiles: downloadFile, isExporting: isDownloading };
};
