import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { Box, Center, Image } from '@chakra-ui/react';
import Button from '@frontend/design-system/components/Button/Button';
import { MdModeEditOutline } from 'react-icons/md';
import { DefaultImageIcon } from '@frontend/shared/src/components/DefaultImageIcon/DefaultImageIcon';
export const PhotoUpload = ({ initialImageUrl, initialImageName, imageRef, onImageChange, acceptedFileTypes, }) => {
    const [image, setImageState] = useState(undefined);
    const [hasImage, setHasImage] = useState(false);
    useEffect(() => {
        var _a, _b, _c;
        if ((initialImageName && initialImageName !== '') ||
            (((_a = imageRef === null || imageRef === void 0 ? void 0 : imageRef.current) === null || _a === void 0 ? void 0 : _a.files) &&
                ((_b = imageRef === null || imageRef === void 0 ? void 0 : imageRef.current) === null || _b === void 0 ? void 0 : _b.files[0]) &&
                ((_c = imageRef === null || imageRef === void 0 ? void 0 : imageRef.current) === null || _c === void 0 ? void 0 : _c.files[0].name))) {
            setHasImage(true);
        }
        else {
            setHasImage(false);
        }
    }, [initialImageName, image]);
    useEffect(() => {
        if (image) {
            onImageChange(URL.createObjectURL(image));
        }
    }, [image]);
    const setImage = () => {
        var _a;
        const input = imageRef === null || imageRef === void 0 ? void 0 : imageRef.current;
        if (input && input.files) {
            const file = (_a = input.files[0]) !== null && _a !== void 0 ? _a : undefined;
            if (file) {
                setImageState(file);
                onImageChange(URL.createObjectURL(file));
            }
        }
    };
    return (_jsxs(Box, Object.assign({ aspectRatio: 1, borderRadius: '8px', boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.15)', boxSizing: 'border-box', alignContent: 'center', position: 'relative', overflow: 'hidden', height: '115px' }, { children: [_jsxs(Box, Object.assign({ flex: '1', display: 'flex', alignItems: 'center', justifyContent: 'center', h: hasImage ? '100%' : '20px' }, { children: [_jsx(Box, { position: 'absolute', top: '0', bottom: '0', right: '0', left: '0', bgImage: 'linear-gradient(to top, rgba(255, 255, 255, 0.8) 10%, transparent 50%)' }), hasImage ? (_jsx(Image, { src: initialImageUrl, w: '100%', h: '100%', fit: 'cover', alt: 'Facility' })) : (_jsx(DefaultImageIcon, {}))] })), _jsx(Center, Object.assign({ position: 'absolute', bottom: '10px', width: '100%' }, { children: _jsx(Button, { text: 'Edit', onClick: () => {
                        var _a;
                        (_a = imageRef === null || imageRef === void 0 ? void 0 : imageRef.current) === null || _a === void 0 ? void 0 : _a.click();
                    }, buttonType: 'secondary', size: 'small', icon: _jsx(MdModeEditOutline, {}), iconPosition: 'left', "aria-label": 'Edit facility photo' }) })), _jsx("input", { type: 'file', style: { display: 'none' }, "data-testid": 'facility-photo-upload', accept: acceptedFileTypes, ref: imageRef, onChange: setImage })] })));
};
