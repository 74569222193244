import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from '@chakra-ui/react';
import Button from '@frontend/design-system/components/Button/Button';
import React, { useEffect, useState } from 'react';
import { useFieldArray } from 'react-hook-form';
import { VscAdd } from 'react-icons/vsc';
import { FacilityStudentsTable } from './FacilityStudentsTable';
var GradeLevel;
(function (GradeLevel) {
    GradeLevel["GL_PRE_K"] = "PRE-K";
    GradeLevel["GL_K"] = "K";
    GradeLevel["GL_1"] = "1";
    GradeLevel["GL_2"] = "2";
    GradeLevel["GL_3"] = "3";
    GradeLevel["GL_4"] = "4";
    GradeLevel["GL_5"] = "5";
    GradeLevel["GL_6"] = "6";
    GradeLevel["GL_7"] = "7";
    GradeLevel["GL_8"] = "8";
    GradeLevel["GL_9"] = "9";
    GradeLevel["GL_10"] = "10";
    GradeLevel["GL_11"] = "11";
    GradeLevel["GL_12"] = "12";
})(GradeLevel || (GradeLevel = {}));
const emptyenrollmentYearsForm = {
    schoolYear: '',
    gradeEnrollment: Object.values(GradeLevel).map((gradeLevel) => ({
        gradeLevel,
        count: '',
    })),
};
export const FacilityStudents = ({ facilityForm, facilityTemplate, validateEnrollment, }) => {
    const { fields, append, remove } = useFieldArray({
        control: facilityForm.control,
        name: 'enrollmentYears',
    });
    const [editableCell, setEditableCell] = useState(undefined);
    const [isAddDisabled, setIsAddDisabled] = useState(true);
    useEffect(() => {
        const enrollmentYears = facilityForm.getValues('enrollmentYears');
        setIsAddDisabled(!validateEnrollment(enrollmentYears));
    }, [fields, facilityForm.watch()]);
    return (_jsxs(Box, Object.assign({ w: '100%' }, { children: [_jsx(FacilityStudentsTable, { facilityForm: facilityForm, fields: fields, remove: remove, editableCell: editableCell, setEditableCell: setEditableCell, facilityTemplate: facilityTemplate }), _jsx(Button, { text: 'Add new', onClick: () => {
                    append(emptyenrollmentYearsForm);
                    setEditableCell({ row: fields.length, column: '' });
                }, isDisabled: fields.length !== 0 && isAddDisabled, icon: _jsx(VscAdd, {}), iconPosition: 'left', buttonType: 'customLink' })] })));
};
