var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useAuth0 } from '@auth0/auth0-react';
import { Box, Center, CloseButton, HStack, ListItem, Text, UnorderedList, useToast, VStack, } from '@chakra-ui/react';
import '@fontsource/montserrat';
import '@fontsource/montserrat/500.css';
import { slgqColors } from '@frontend/design-system/theme/theme';
import { useDependencies } from '@frontend/domain/contexts/Dependencies/DependenciesContext';
import { useOrganizationSummaries } from '@frontend/domain/contexts/Organization/OrganizationSummaryContext';
import { useOrganization } from '@frontend/domain/hooks/Organization/OrganizationHook';
import { startWorker } from '@frontend/domain/testing/msw/worker';
import { RequireFacilityAccess } from '@frontend/shared/src/components/Access/RequireFacilityAccess';
import { RequireOrgAccess } from '@frontend/shared/src/components/Access/RequireOrgAccess';
import { RequireOrgAppAccess } from '@frontend/shared/src/components/Access/RequireOrgAppAccess';
import { UnauthorizedOrg } from '@frontend/shared/src/components/Access/Unauthorized';
import { RequireWithinOrganization } from '@frontend/shared/src/components/Routing/RequireWithinOrganization';
import { AppPaths, AppRoutes, } from '@frontend/shared/src/components/Routing/RouteConstants';
import { UserControl } from '@frontend/shared/src/components/Routing/UserControl';
import React, { useEffect } from 'react';
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import { authConfig } from './authConfig';
import { PageLayout } from './components/layouts/PageLayout';
import './config/style.css';
import { handlers } from './Mocks/handlers';
import { Logout } from './pages/Logout/Logout';
import { FacilitiesPage } from './pages/Selection/Facilities/FacilitiesPage';
import { FacilityPage } from './pages/Selection/Facilities/FacilityPage';
import { GalleryPage } from './pages/Selection/Gallery/GalleryPage';
import { SupportRedirect } from './pages/Selection/SupportRedirect';
import { apiService, platformApiService } from './services';
import { useAppSelector } from './store/hooks';
import { selectUpdateAvail } from './store/serviceWorkerSlice';
import { IAppNames } from '@frontend/domain/models/App';
function App() {
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const { setToken } = useDependencies();
    const updateAvailable = useAppSelector(selectUpdateAvail);
    const toast = useToast();
    const closeToast = () => {
        toast.close('updateToast');
    };
    useEffect(() => {
        if (updateAvailable) {
            console.log('There is an update available!');
            toast({
                containerStyle: {
                    border: 'none',
                },
                id: 'updateToast',
                position: 'top-right',
                render: () => (_jsxs(VStack, Object.assign({ color: slgqColors.primWhite, p: '13px 13px 20px 20px', bg: slgqColors.primBlue, borderRadius: '15px', border: '0.5px, solid rgba(43, 43, 43, 1)' }, { children: [_jsx(HStack, Object.assign({ justifyContent: 'end', h: '16px', w: '100%' }, { children: _jsx(CloseButton, { alignItems: 'baseline', "aria-label": 'Close Update Notification', color: slgqColors.primWhite, h: '100%', justifyContent: 'end', onClick: closeToast, size: 'lg' }) })), _jsx(Text, Object.assign({ fontSize: '24px', m: '0px !important', color: slgqColors.primWhite }, { children: "A new update is available!" })), _jsxs(UnorderedList, Object.assign({ ms: '20px' }, { children: [_jsx(ListItem, Object.assign({ mb: '10px', ml: '20px' }, { children: "If you are in the web browser, you just need to close all the tabs you have opened with the FA tool." })), _jsx(ListItem, Object.assign({ ml: '20px' }, { children: "If you are in the downloaded app, you just need to close and reopen it." }))] }))] }))),
                duration: 600000,
                isClosable: true,
            });
        }
    }, [updateAvailable]);
    useEffect(() => {
        if (typeof window !== 'undefined') {
            if (process.env.REACT_APP_API_MOCKING === 'enabled') {
                startWorker(handlers); // msiq backend mocks specific to FCA added
            }
        }
    }, []);
    useEffect(() => {
        if (isAuthenticated && (user === null || user === void 0 ? void 0 : user.email)) {
            getAccessTokenSilently({
                authorizationParams: {
                    audience: authConfig.authorizationParams.platformAudience,
                    scope: 'email read:current_user update:current_user_metadata',
                },
            }).then((token) => {
                setToken(token);
            });
            // TODO: remove once we only make service requests through useDependencies
            apiService.setTokenRetrievalFunction(() => __awaiter(this, void 0, void 0, function* () {
                return yield getAccessTokenSilently({
                    authorizationParams: {
                        audience: authConfig.authorizationParams.platformAudience,
                        scope: 'email read:current_user update:current_user_metadata',
                    },
                });
            }));
            platformApiService.setTokenRetrievalFunction(() => __awaiter(this, void 0, void 0, function* () {
                return yield getAccessTokenSilently({
                    authorizationParams: {
                        audience: authConfig.authorizationParams.platformAudience,
                        scope: 'email read:current_user update:current_user_metadata',
                    },
                });
            }));
        }
    }, [isAuthenticated, user]);
    const GlobalFcaDetails = ({ children, }) => {
        const { selectedOrganizationIdentity: orgIdentity } = useOrganizationSummaries();
        const navigate = useNavigate();
        useEffect(() => {
            const baseRoute = AppPaths.organization.withOrgId(orgIdentity);
            if (location.pathname === baseRoute ||
                location.pathname === `${baseRoute}/`) {
                // Needed to properly redirect to landing route if just /organization/:oid
                navigate(AppPaths.facilities.base(orgIdentity));
            }
        }, [location, orgIdentity]);
        return _jsx(_Fragment, { children: children });
    };
    const { home: base, organization, facilities, logout, support, photos, } = AppPaths;
    return (_jsx(Box, Object.assign({ className: 'App', h: '100vh' }, { children: _jsxs(Routes, { children: [_jsx(Route, { path: logout, element: _jsx(Logout, {}) }), _jsxs(Route, Object.assign({ path: base, element: _jsx(UserControl, Object.assign({ authorizationParams: authConfig.authorizationParams }, { children: _jsx(Outlet, {}) })) }, { children: [_jsx(Route, { path: base, element: _jsx(Navigate, { to: organization.base }) }), _jsx(Route, { path: support, element: _jsx(SupportRedirect, {}) }), _jsx(Route, Object.assign({ path: organization.base, element: _jsx(RequireWithinOrganization, Object.assign({ basePath: organization.base }, { children: _jsx(Outlet, {}) })) }, { children: _jsxs(Route, Object.assign({ path: organization.withOrgId(), element: _jsx(GlobalFcaDetails, { children: _jsx(RequireOrgAccess, Object.assign({ unauthorizedPage: _jsx(UnauthorizedPageWrapper, {}) }, { children: _jsx(Outlet, {}) })) }) }, { children: [_jsx(Route, { path: organization.withOrgId(), element: _jsx(NavigateToFCAHome, {}) }), _jsx(Route, { path: photos.base(), element: _jsx(RequireOrgAppAccess, Object.assign({ unauthorizedPage: _jsx(UnauthorizedPageWrapper, {}), requiredApp: IAppNames.FCA }, { children: _jsx(GalleryPage, {}) })) }), _jsx(Route, { path: facilities.base(), element: _jsx(FacilitiesPage, {}) }), _jsx(Route, Object.assign({ path: facilities.base(), element: _jsx(RequireFacilityAccess, Object.assign({ unauthorizedPage: _jsx(UnauthorizedPageWrapper, {}) }, { children: _jsx(Outlet, {}) })) }, { children: _jsx(Route, { path: facilities.withFacilityId(), element: _jsx(FacilityPage, {}) }) }))] })) }))] }))] }) })));
}
const NavigateToFCAHome = () => {
    const { selectedOrganizationIdentity } = useOrganizationSummaries();
    return selectedOrganizationIdentity ? (_jsx(Navigate, { to: AppRoutes.myFacilities(selectedOrganizationIdentity) })) : (_jsx(_Fragment, {}));
};
const UnauthorizedPageWrapper = () => {
    const { selectedOrganizationIdentity } = useOrganizationSummaries();
    const { organizationDetails } = useOrganization({
        organizationIdentity: selectedOrganizationIdentity,
    });
    return (_jsx(PageLayout, { children: _jsx(Center, Object.assign({ h: '85vh' }, { children: _jsx(VStack, { children: _jsx(UnauthorizedOrg, { organization: organizationDetails }) }) })) }));
};
export default App;
