var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useFacilityContext } from '@/contexts/Facilities/FacilityPageContext';
import { FacilityMapTypes } from '@/objects/FCAHelperInterfaces';
import { PageModes } from '@/objects/FCAInterfaces';
import { useMapApi } from '@/services/useMapApi';
import { getImageUrl, toBlobStoreContainerUrl } from '@/utils/photoFileUtils';
import { Flex, useToast } from '@chakra-ui/react';
import { CanvasMode, FloorPlanCanvas, } from '@frontend/design-system/components/FloorPlan/FloorPlanCanvas';
import { usePlannerSettingsContext } from '@frontend/domain/contexts/Settings/PlannerSettingsContext';
import React, { useEffect, useState } from 'react';
import { ConditionKey } from './ConditionKey';
import { FloorPlanMeasures } from './FloorPlanMeasuresLayer';
import { useFloorplanCanvasShape } from './useFloorplanCanvasShapes';
const FloorPlanWrapper = () => {
    const [planSpaces, setPlanSpaces] = useState([]);
    const [imgUrl, setImgUrl] = useState('');
    const { orgAgeAdjustedCondition: orgHasAgeAdjustedCondition } = usePlannerSettingsContext();
    const { mapDetails } = useMapApi();
    const toast = useToast();
    const { canvasMode, pageMode, mapMode, selectedPlan, currentSpaces, selectedSpaceIdentity, selectedAssetIdentity, drawAssetPin, setDrawAssetPin, drawSpaceShape, setDrawSpaceShape, selectSpace, setPageMode, isDataLoading, } = useFacilityContext();
    const { getShape, getPin, getMeasureShapes, getMeasureSplitShapes } = useFloorplanCanvasShape();
    const [selectedMeasure, setSelectedMeasure] = useState(FacilityMapTypes.BUILDING_AGE);
    useEffect(() => {
        const loadSpaces = () => __awaiter(void 0, void 0, void 0, function* () {
            if (selectedPlan && mapMode === true) {
                const spaces = yield mapDetails.fetch(selectedPlan.identity);
                setPlanSpaces(spaces);
            }
        });
        loadSpaces();
    }, [mapMode, selectedPlan, currentSpaces]);
    const [showAgeAdjustedCondition, setShowAgeAdjustedCondition] = useState(orgHasAgeAdjustedCondition);
    useEffect(() => {
        if (orgHasAgeAdjustedCondition !== showAgeAdjustedCondition) {
            setShowAgeAdjustedCondition(orgHasAgeAdjustedCondition);
        }
    }, [orgHasAgeAdjustedCondition]);
    const shouldRenderCondition = selectedMeasure === FacilityMapTypes.CASEWORK ||
        selectedMeasure === FacilityMapTypes.CEILING ||
        selectedMeasure === FacilityMapTypes.FLOORING;
    const getAssetPins = (spaces) => {
        // only filter spaces if one is selected
        const selectedSpacesToParse = spaces.filter((space) => selectedSpaceIdentity === undefined ||
            space.identity === selectedSpaceIdentity);
        if (canvasMode === CanvasMode.DRAW_PIN &&
            pageMode !== PageModes.ADD_ASSET) {
            return selectedSpacesToParse.flatMap((space) => space.assets
                .filter((asset) => {
                if (asset.identity !== selectedAssetIdentity) {
                    return true;
                }
                else {
                    return false;
                }
            })
                .filter((asset) => asset.location)
                .map((asset) => getPin(asset.identity, asset.location || {}, //Should never hit null case since we are filtering above
            space.identity)));
        }
        else {
            return selectedSpacesToParse.flatMap((space) => space.assets
                .filter((asset) => asset.location)
                .map((asset) => getPin(asset.identity, asset.location || {}, //Should never hit null case since we are filtering above
            space.identity)));
        }
    };
    const calculatePinColor = (pin) => {
        const spaceShapes = getSpaceShapes(currentSpaces);
        // TODO logic is tricky to follow.
        // We need to confirm it is working for HVAC pins.
        const pinSpace = spaceShapes.find((space) => space.identity === pin.associatedShapeIdentity);
        if (!pinSpace)
            return undefined;
        const selectedSpace = spaceShapes.find((space) => selectedSpaceIdentity === space.identity);
        return selectedSpace !== undefined
            ? pinSpace.style.fill.selected
            : pinSpace.style.fill.unselected;
    };
    // This will need to get updated to be more scalable as we search for pins of different asset types
    const getHVACPins = (spaces, measure) => {
        if (measure !== FacilityMapTypes.HVAC)
            return [];
        const ahuAssets = [];
        (spaces !== null && spaces !== void 0 ? spaces : []).forEach((space) => {
            const assets = space.assets
                ? space.assets
                    .filter((asset) => asset.assetType === 'Air Handling System' &&
                    !!asset.pin &&
                    (!selectedSpaceIdentity ||
                        asset.spaceIdentity ===
                            selectedSpaceIdentity))
                    .map((ahuAsset) => {
                    var _a, _b;
                    return ({
                        identity: ahuAsset.assetIdentity,
                        location: {
                            x: (_a = ahuAsset.pin) === null || _a === void 0 ? void 0 : _a.x,
                            y: (_b = ahuAsset.pin) === null || _b === void 0 ? void 0 : _b.y,
                        },
                        associatedShapeIdentity: ahuAsset.spaceIdentity,
                    });
                })
                    .map((pin) => (Object.assign(Object.assign({}, pin), { fill: calculatePinColor(pin) })))
                : [];
            ahuAssets.push(...assets);
        });
        return ahuAssets;
    };
    const getSpaceShapes = (spaces) => {
        if (canvasMode === CanvasMode.EDIT_SPACE_LOCATION ||
            (canvasMode === CanvasMode.DRAW_SPACE_LOCATION &&
                pageMode !== PageModes.ADD_SPACE)) {
            return spaces
                .filter((space) => space.identity !== selectedSpaceIdentity)
                .map((space) => getShape(space, selectedSpaceIdentity));
        }
        else {
            return spaces.map((space) => getShape(space, selectedSpaceIdentity));
        }
    };
    // move accessors to context
    const selectedSpace = currentSpaces === null || currentSpaces === void 0 ? void 0 : currentSpaces.find((space) => space.identity === selectedSpaceIdentity);
    const selectedAsset = selectedSpace === null || selectedSpace === void 0 ? void 0 : selectedSpace.assets.find((asset) => asset.identity === selectedAssetIdentity);
    const onSelectSpace = (shape) => {
        // Needed to ensure we can place a pin on an existing space
        // selecting a space will change pagemode to view which cancels asset pin redraw
        if (canvasMode !== CanvasMode.DRAW_PIN &&
            pageMode !== PageModes.ADD_SPACE &&
            pageMode !== PageModes.EDIT &&
            !isDataLoading) {
            selectSpace(shape.identity);
            setPageMode(PageModes.VIEW);
        }
    };
    const isAssetSelected = (pin) => {
        return selectedAssetIdentity === pin.identity;
    };
    useEffect(() => {
        const loadImageUrl = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            const imageUrl = yield getImageUrl(toBlobStoreContainerUrl((_a = selectedPlan === null || selectedPlan === void 0 ? void 0 : selectedPlan.planFileName) !== null && _a !== void 0 ? _a : ''));
            imageUrl
                ? setImgUrl(imageUrl)
                : toast({
                    title: 'Image Failure',
                    description: 'Could not pull up plan image.',
                    status: 'error',
                    isClosable: true,
                });
        });
        loadImageUrl();
    }, [selectedPlan]);
    return (_jsxs(Flex, { children: [_jsx(FloorPlanCanvas, { canvasMode: canvasMode, selectedShape: selectedSpace
                    ? getShape(selectedSpace, selectedSpaceIdentity)
                    : undefined, selectedPin: (selectedAsset === null || selectedAsset === void 0 ? void 0 : selectedAsset.location)
                    ? getPin(selectedAsset.identity, selectedAsset.location)
                    : undefined, planImageUrl: imgUrl, isViewOnly: false, drawShape: drawSpaceShape, drawPin: drawAssetPin, pins: mapMode
                    ? getHVACPins(planSpaces, selectedMeasure)
                    : getAssetPins(currentSpaces), shapes: mapMode
                    ? getMeasureShapes(planSpaces, selectedMeasure !== null && selectedMeasure !== void 0 ? selectedMeasure : FacilityMapTypes.BUILDING_AGE, showAgeAdjustedCondition, selectedSpaceIdentity)
                    : getSpaceShapes(currentSpaces), splitShapes: mapMode
                    ? getMeasureSplitShapes(planSpaces, selectedMeasure !== null && selectedMeasure !== void 0 ? selectedMeasure : FacilityMapTypes.BUILDING_AGE, showAgeAdjustedCondition, selectedSpaceIdentity)
                    : [], isPinSelected: isAssetSelected, onSelectShape: onSelectSpace, onSetDrawPin: setDrawAssetPin, onUpdateShape: setDrawSpaceShape }), mapMode && shouldRenderCondition && (_jsx(ConditionKey, { width: '100%', showAgeAdjustment: showAgeAdjustedCondition, toggleShowAgeAdjustment: () => {
                    setShowAgeAdjustedCondition((prev) => !prev);
                } })), mapMode && (_jsx(FloorPlanMeasures, { spaces: planSpaces, measure: selectedMeasure, selectMeasure: setSelectedMeasure }))] }));
};
export default FloorPlanWrapper;
