import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import Body from '../Typography/Body/Body';
const parseNumberAsString = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
export const NumberData = ({ value, unit, textStyle, }) => {
    const formattedNumber = parseNumberAsString(value);
    const displayValue = unit ? `${formattedNumber} ${unit}` : formattedNumber;
    return _jsx(Body, Object.assign({ textStyle: textStyle }, { children: displayValue }));
};
