import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, FormControl, FormErrorMessage, HStack, Input, Text, VStack, } from '@chakra-ui/react';
import React from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { BiSolidTrashAlt } from 'react-icons/bi';
import { validateYearRange } from '../../Main/Sidebar/DetailsSidebar/utility/Validations';
export const FacilityAdditionInput = ({ index, removeAddition, formContext, }) => {
    const { control } = formContext;
    const additions = useWatch({ name: 'additions', control }) || [];
    const addition = additions[index];
    const minYear = 1700;
    const maxYear = new Date().getFullYear() + 2;
    const isPartiallyFilled = !!(addition === null || addition === void 0 ? void 0 : addition.squareFootage) || !!(addition === null || addition === void 0 ? void 0 : addition.yearBuilt);
    return (_jsxs(HStack, Object.assign({ w: '100%', align: 'start', spacing: 4 }, { children: [_jsx(Controller, { name: `additions.${index}.squareFootage`, control: control, rules: {
                    validate: (value) => {
                        if (value === '' && (addition === null || addition === void 0 ? void 0 : addition.yearBuilt) === '')
                            return true;
                        if (isPartiallyFilled && (!value || value === '')) {
                            return 'Square footage is required';
                        }
                        if (value && Number(value) < 0) {
                            return 'Square footage must be greater than 0';
                        }
                        return true;
                    },
                }, render: ({ field, fieldState: { error } }) => (_jsx(VStack, Object.assign({ align: 'start', w: 'full' }, { children: _jsxs(FormControl, Object.assign({ isInvalid: !!error }, { children: [_jsx(Input, { value: field.value, placeholder: 'SQFT', type: 'number', onChange: (e) => {
                                    field.onChange(e);
                                } }), _jsx(FormErrorMessage, { children: error === null || error === void 0 ? void 0 : error.message })] })) }))) }), _jsx(Text, Object.assign({ pt: 2, whiteSpace: 'nowrap', alignSelf: 'baseline', fontSize: 'sm' }, { children: "added in" })), _jsx(Controller, { name: `additions.${index}.yearBuilt`, control: control, rules: {
                    validate: (year) => {
                        if (year === '' && (addition === null || addition === void 0 ? void 0 : addition.squareFootage) === '')
                            return true;
                        if (isPartiallyFilled && (!year || year === '')) {
                            return 'Year is required';
                        }
                        // Validate year range
                        if (!validateYearRange(Number(year), minYear, maxYear)) {
                            return `Year must be between ${minYear} and ${maxYear}`;
                        }
                        // Validate uniqueness
                        const isUnique = additions.filter((add, i) => i === index || (add === null || add === void 0 ? void 0 : add.yearBuilt) === year).length === 1;
                        if (!isUnique) {
                            return 'An addition with this year already exists. Use a different year or add this square footage to the existing addition';
                        }
                        return true;
                    },
                }, render: ({ field, fieldState: { error } }) => (_jsx(VStack, Object.assign({ align: 'start', w: 'full' }, { children: _jsxs(FormControl, Object.assign({ isInvalid: !!error }, { children: [_jsx(Input, { value: field.value, placeholder: 'Year', type: 'number', onChange: (e) => {
                                    field.onChange(e);
                                } }), _jsx(FormErrorMessage, { children: error === null || error === void 0 ? void 0 : error.message })] })) }))) }), _jsx(Button, Object.assign({ "aria-label": 'delete addition', colorScheme: 'none', fontSize: '20px', color: 'primary.blue', background: 'transparent', alignSelf: 'start', onClick: removeAddition, p: 0, minW: 'unset' }, { children: _jsx(BiSolidTrashAlt, {}) }))] })));
};
