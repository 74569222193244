import { jsx as _jsx } from "react/jsx-runtime";
import { Flex } from '@chakra-ui/react';
import { EmptyState } from '@frontend/design-system/components/EmptyState/EmptyState';
export const FacilitiesPageInstructions = ({ canAdd, }) => {
    const getFacilitiesInstructions = () => {
        if (canAdd) {
            return 'Click on "+ New Facility" next to the organization selector to add your facilities.';
        }
        else {
            return 'To be able to add facilities, please contact your client concierge.';
        }
    };
    return (_jsx(Flex, Object.assign({ justifyContent: 'center', h: '85vh' }, { children: _jsx(EmptyState, { displayText: getFacilitiesInstructions() }) })));
};
