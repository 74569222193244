import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Table, Tbody, Td, Text, Thead, Tr } from '@chakra-ui/react';
import React from 'react';
import { FacilityStudentsTableRow } from './FacilityStudentTableRow';
export const FacilityStudentsTable = ({ facilityForm, fields, remove, editableCell, setEditableCell, facilityTemplate, }) => {
    const handleCellClick = (rowIndex, columnName) => {
        if ((editableCell === null || editableCell === void 0 ? void 0 : editableCell.row) !== rowIndex ||
            (editableCell === null || editableCell === void 0 ? void 0 : editableCell.column) !== columnName) {
            setEditableCell({ row: rowIndex, column: columnName });
        }
    };
    return (_jsx(Box, Object.assign({ maxH: '260px', overflowX: 'auto', overflowY: fields.length > 4 ? 'auto' : 'unset' }, { children: _jsxs(Table, Object.assign({ w: '100%', minWidth: '800px', variant: 'simple', size: 'sm', sx: {
                borderSpacing: '0',
                tableLayout: 'fixed',
            } }, { children: [_jsx(Thead, Object.assign({ position: 'sticky', top: '0', bg: 'white', zIndex: '1' }, { children: _jsxs(Tr, Object.assign({ backgroundColor: 'secondary.light.200', height: '52px' }, { children: [_jsx(Td, { w: '160px' }), facilityTemplate === null || facilityTemplate === void 0 ? void 0 : facilityTemplate.gradeLevels.map((grade) => {
                                return (_jsx(Td, Object.assign({ textAlign: 'center', p: 0 }, { children: _jsx(Text, Object.assign({ textStyle: 'body.regular' }, { children: grade })) }), grade));
                            })] })) })), _jsx(Tbody, { children: fields.length > 0 &&
                        fields.map((field, index) => (_jsx(FacilityStudentsTableRow, { formContext: facilityForm, field: field, index: index, removeRow: () => remove(index), editableCell: editableCell, onCellClick: (columnName) => handleCellClick(index, columnName), schoolYears: (facilityTemplate === null || facilityTemplate === void 0 ? void 0 : facilityTemplate.schoolYears) || [] }, field.id))) })] })) })));
};
