import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Divider, Flex, Icon, Text, } from '@chakra-ui/react';
import React from 'react';
import { NavLink } from './NavLink';
import { NavSubLink } from './NavSubLink';
export const NavBarLinkGroup = ({ group: { icon, selectedIcon, link, title, subLinks }, expanded, }) => {
    const checkIfAnyCurrent = (links) => {
        return links.some((link) => { var _a; return link.isCurrentLink || checkIfAnyCurrent((_a = link.subGroups) !== null && _a !== void 0 ? _a : []); });
    };
    const isCurrent = subLinks ? checkIfAnyCurrent(subLinks) : false;
    return (_jsxs(AccordionItem, Object.assign({ border: 'none' }, { children: [_jsx("h2", Object.assign({ "data-testid": title }, { children: subLinks ? (_jsxs(AccordionButton, Object.assign({ justifyContent: 'space-between' }, { children: [_jsxs(Flex, Object.assign({ alignItems: 'center' }, { children: [_jsx(Icon, { as: isCurrent ? selectedIcon : icon, boxSize: '40px', mr: '20px' }), expanded && (_jsx(Text, Object.assign({ textAlign: 'center', fontWeight: isCurrent ? 600 : 400 }, { children: title })))] })), expanded && subLinks && subLinks.length > 0 && (_jsx(AccordionIcon, {}))] }))) : expanded ? (_jsx(NavLink, Object.assign({}, link, { children: _jsxs(Flex, Object.assign({ pl: '16px', py: '8px', alignItems: 'center', cursor: 'pointer', _hover: {
                            backgroundColor: 'rgba(0, 0, 0, 0.04)',
                        } }, { children: [_jsx(Icon, { as: isCurrent ? selectedIcon : icon, boxSize: '40px', mr: '20px' }), _jsx(Text, Object.assign({ textAlign: 'center', fontWeight: isCurrent ? 600 : 400 }, { children: title }))] })) }))) : (_jsx(Flex, Object.assign({ pl: '16px', py: '8px', alignItems: 'center' }, { children: _jsx(Icon, { as: isCurrent ? selectedIcon : icon, boxSize: '40px', mr: '20px' }) }))) })), expanded && subLinks && subLinks.length > 0 && (_jsx(AccordionPanel, { children: _jsx(Box, Object.assign({ background: 'white', boxShadow: 'inset 0px 0px 4px rgba(0, 0, 0, 0.2)', borderRadius: '2px', w: '100%', py: '10px', maxHeight: '250px', overflowY: 'auto', alignItems: 'left' }, { children: subLinks && _jsx(NavBarSubLinkGroup, { subLinks: subLinks }) })) }))] })));
};
const NavBarSubLinkGroup = ({ subLinks, depth = 1, }) => {
    const linkColor = 'brand.primBlue';
    return (_jsx(_Fragment, { children: subLinks.map((link) => (_jsxs(Box, { children: [_jsx(Box, Object.assign({ pl: `${25 * depth}px` }, { children: _jsx(NavSubLink, Object.assign({}, link, { color: linkColor })) })), link.divideAfter && (_jsx(Divider, { width: '85%', mb: '10px', mx: 'auto' })), link.subGroups && (_jsx(NavBarSubLinkGroup, { subLinks: link.subGroups, depth: (depth !== null && depth !== void 0 ? depth : 1) + 1 }))] }, `link-${link.title}`))) }));
};
